<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-left">
          <div class="flex justify-start">
            <BackButton @click="goBack"></BackButton>
          </div>
        </template>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl whitespace-nowrap">{{ purchaseTitle }}</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <LoadingCard v-if="isLoading" />
      <ErrorCard v-else-if="errorFetching" message="Dispense details not available"/>
      <CashDispenseDetailCard v-else/>
    </template>
  </PageTemplate>
</template>

<script>
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import CashDispenseDetailCard from "../../components/Helpers/Cards/CashDispenseDetailCard";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import BackButton from "../../components/Helpers/Buttons/BackButton";
import {mapActions} from "vuex";

export default {
  name: "DispenserLogDetail",
  components: {
    PageTemplate,
    BasicNav,
    BackButton,
    ErrorCard,
    LoadingCard,
    CashDispenseDetailCard
  },
  data() {
    return {
      purchaseTitle: "Cash Dispenser Purchase Detail",
      isLoading: false,
      errorFetching: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions("TransactionLogs", ["getTransactionLogCashDetails"]),
    async fetchData() {
      this.isLoading = true;
      const id = this.$route.params.id;
      try {
        await this.getTransactionLogCashDetails(id);
      } catch (e){
        this.errorFetching = true;
      } finally {
        this.isLoading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>
